body {
    margin: 0;
    font-family: 'Work Sans', 'Open Sans', 'Quicksand', 'Mukta', 'Work Sans', 
    -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

input {
    font-family: 'Work Sans', 'Open Sans', 'Quicksand', 'Mukta', 'Work Sans', 
    -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

button {
    font-family: 'Work Sans', 'Open Sans', 'Quicksand', 'Mukta', 'Work Sans', 
    -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}


.app {
    width: 100%;
    height: 100vh;
    overflow: hidden;
    background-image: linear-gradient(to bottom right, #edf0f7, #dee2eb);
    display: flex;
    flex-direction: column;
}

.header {
    margin: auto;
    margin-top: 20vh;
    display: flex;
    flex-direction: row;
    font-weight: 600;
}

.headerText {
    font-size: 42px;
}

.headerTextO {
    font-size: 50px;
    margin-left: 2px;
    margin-right: 2px;
    margin-top: -7px;
    color: #00953c;
}

.info {
    margin: auto;
    margin-top: -5vh;
    text-align: center;
    font-size: 20px;
    font-weight: 400;
    /* background-color: rgba(255, 0, 0, 0.164); */
}

.contactSection{
    margin: auto;
    /* display: flex;
    flex-direction: column; */
    margin-top: -5vh;
    height: 30vh;
}

.contact {
    margin: auto;
    /* text-align: center; */
    font-size: 20px;
    font-weight: 400;
    /* background-color: rgba(255, 0, 0, 0.164); */
    margin-bottom: 3vh;
}

.phone {
    margin: auto;
    display: flex;
    flex-direction: row;
    /* width: 260px; */
    font-size: 20px;
    /* background-color: rgba(255, 0, 0, 0.164); */
    margin-bottom: 2vh;
}

.email {
    margin: auto;
    display: flex;
    flex-direction: row;
    /* width: 260px; */
    font-size: 20px;
    /* background-color: rgba(255, 0, 0, 0.164); */
}